body {
    margin: 0;
}

/* Customize this preloader */
:root {
    --preload-color-foreground: grey;
    --preload-color-accent: #03a9f4;
    --preload-spinner-size: 128px;
    --preload-spinner-width: 3px;
}

#app-preloader {
    position: relative;
    width: 100%;
    height: 50%;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    color: var(--preload-color-foreground);
    transition: .3s ease-in-out;
}


#app-preloader .preloader-spinner {
    position: relative;
    width: var(--preload-spinner-size);
    height: var(--preload-spinner-size);
    animation: preloader-spinner .5s linear infinite;
}

#app-preloader .preloader-spinner .preloader-spinner-icon {
    width: var(--preload-spinner-size);
    height: var(--preload-spinner-size);
    stroke: var(--preload-color-foreground);
    stroke-width: var(--preload-spinner-width);
    stroke-dasharray: 314%, 314%;
    animation: preloader-spinner-icon-anim 1s linear infinite alternate;
}

@keyframes preloader-logo-anim {
    0% {
        filter: opacity(0);
        transform: translateY(-30px);
    }

    100% {
        filter: opacity(1);
        transform: translateY(0);
    }
}


@keyframes preloader-spinner {
    0% {
        transform: rotate(0);
    }

    100% {
        transform: rotate(360deg);
    }
}

@keyframes preloader-spinner-icon-anim {
    0% {
        stroke-dasharray: 0%, 314%;
    }

    100% {
        stroke-dasharray: 314%, 314%;
    }
}