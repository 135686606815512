

.name-row {
    font-size: 60px;
    font-family: Morganite, sans-serif;
    color: #1c1c1c;
    text-decoration: none;
}

.navbar{
    min-height:100px;
    font-family: morganite, sans-serif;
    font-size: 48px;
    max-width: 1144px;
    margin: auto;
    padding-top: 40px;
    transition: font-size 0.5s ease;
}

.navbar span{
    margin: 15px;
    font-size: 60px;
}
.navbar ul{
    display: flex;
    flex-wrap: wrap;
    padding-inline-start:unset;
    margin-top: unset;
    margin-bottom: unset;
}

.navbar ul li{
    list-style: none;
    margin: 15px;
    text-decoration: none;
    color: grey;
    transition: color 0.7s ease;
}

.navbar ul li a:link, .navbar ul li a:visited{
    text-decoration: none;
    color: grey;
}

.navbar ul li a:link:hover, .navbar ul li a.active {
    text-decoration: underline;
    color: black;
    transition: color 0.7s ease;
}

.navbar a {
    margin: auto 0;
}

.shop-navbar {
    display: flex;
    justify-content: space-between;
}
@media only screen and (max-width: 767px) {
    .navbar{
        font-size: 30px;
    }
    .navbar ul li:first-child{
        margin-left: 15px;
    }

    .navbar ul li:last-child{
        margin-right: 15px;
    }

    .navbar ul li{
        margin: auto;
    }
}

@media only screen and (min-width: 768px) {

    .navbar {
        display: flex;
        justify-content: space-between;
    }
}

@media only screen and (min-width: 992px) {


}