
.slick-slide div img{
  height: 66vh;


}

@media only screen and (max-width: 1025px) {
  .slick-slide div img{
    max-height: 500px;
  }
}

.slick-prev, .slick-next{
  height:30px;
  width: 30px;
}
.slick-prev{
  left: 25px;
  z-index: 1;
}

.slick-next{
  right: 25px;
}

.slick-prev::before, .slick-next::before{
  font-size:30px;
}

.slick-slide{
  opacity: 0.7;
  transition: opacity 0.7s ease;
}

.slick-slide img.slide-img{
  width:unset!important;
}

.slick-current{
  opacity: 1;
}

.slick-slide:hover{
  opacity: 1;
}


.carousel-container img {
  width: 100%;
}