@import url('https://fonts.googleapis.com/css?family=Open+Sans');

.arrow-up {
    width: 0;
    height: 0;
    border-left: 10px solid transparent;
    border-right: 10px solid transparent;
    position: absolute;
    right: 0;
    top: 0;
    border-bottom: 10px solid white;
}

.arrow-up:after {
    content: "";
    position: absolute;
    width: 100%;

    bottom: 1px;
    z-index: -1;
    transform: scale(1.5);
    box-shadow: 0 0 8px 2px #000000;
}


.popover-container {
    position: absolute;
    right: 1rem;
    padding-top: 10px;
    min-width: 90%;
    z-index: 2;
}

.popover-content {
    background: white;
    box-shadow: 0 4px 8px 0 rgba(0, 0, 0, 0.2);
    padding: 2rem;

    display: flex;
    flex-direction: column;
    justify-content: space-between;
}

.cart-item {
    padding-bottom: 0.5rem;
    display: flex;
    justify-content: space-between;
}

.cart-item-name {
    width: 50%;
    text-align: start;
}

.cart-right {
    flex-grow: 1;
    display: flex;
    justify-content: space-between;
}

.cart-item-price {
    font-weight: bold;
}

.cart-items-total {
    font-size: 2rem;
    font-weight: bold;
    padding: 1rem 0;
}

.cart-icon-wrapper {
    position: relative;
    cursor: pointer;
}

.cart-item-count {
    border-radius: 50%;
    padding: 3px;
    line-height: 10px;
    width: 10px;
    height: 10px;

    background: rgb(255, 95, 0);
    color: white;
    text-align: center;
    position: absolute;
    font-size: .5rem;

    right: 0;
    top: 50%;
}

/*.products-wrapper {*/
/*    display: grid;*/
/*    grid-gap: 1rem;*/
/*    grid-template-columns: repeat(4, 1fr);*/
/*}*/

.product.card {
    box-shadow: 0 4px 8px 0 rgba(0, 0, 0, 0.2);
    text-align: center;

}

.product.card .product-image-wrapper {
    width: 100%;
    height: 50%;
    overflow: hidden;
}

.product.card .product-image {
    width: 100%;
    height: 100%;
    object-fit: cover;
}

.product-image:hover {
    scale: 1.1;
    transition: all .2s ease-in-out;
}


.product.card {
    display: flex;
    flex-direction: column;
    justify-content: space-between;
}


.product-card-details {
    flex-grow: 1;
    display: flex;
    flex-direction: column;
    justify-content: space-between;
}

.product-card-text {
    padding: 0 1rem;
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    flex-grow: 1;
}

.product-btn {
    border: none;
    outline: 0;
    padding: 12px;
    color: white;
    background-color: #1c1c1c;
    text-align: center;
    cursor: pointer;
    width: 100%;
    font-size: 18px;
}

.product-btn:hover {
    opacity: 0.7;
}

@media only screen and (max-width: 767px) {

    .popover-content {
        padding: 1rem;
    }
    .products-wrapper{
        display: block;
    }
    .product.card {
        margin-bottom:1rem;
    }

}



@media only screen and (min-width: 768px) {

    .products-wrapper{
        display: grid;
        grid-gap: 1rem;
        grid-template-columns: repeat(2,1fr);
    }
}

@media only screen and (min-width: 992px) {

    .products-wrapper{
        display: grid;
        grid-gap: 1rem;
        grid-template-columns: repeat(4,1fr);
    }
}