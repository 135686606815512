.popover-wrapper {
    text-align: end;
    padding: 1rem 0;
}

.cart-item-amount-wrapper{
    display: flex;
}

.cart-item-btn{
    background-color: #1c1c1c;
    color: white;
    margin: 0 .5rem;
    border: none;
    cursor: pointer;
    width: 2rem;
    height: 2rem;
    vertical-align: middle;
}

.cart-item-btn:hover{
    opacity: 0.7;
}

.cart-item-remove{
    opacity: 0.7;
    font-size: 6px;
    margin-right: 0.5rem;
    cursor: pointer;
}

.cart-item-remove svg {
   padding-top: 50%;
}

.cart-item-remove:hover{
    opacity: 1;

}