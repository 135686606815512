.container {
    margin: auto auto;
    position: relative;
    font-family: 'Open Sans', sans-serif;
    padding:3rem 1rem;
}

a{
    text-decoration: none;
}

@media only screen and (max-width: 767px) {

    .container {
        margin: 0 1rem;
    }
}

@media only screen and (min-width: 768px) {

    .container{
        max-width: 768px;
    }
}

@media only screen and (min-width: 992px) {

    .container{
        max-width: 1144px;
    }
}